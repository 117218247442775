@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

html,
body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Space Mono', monospace;
  color: #000000;
}


h1 {
  font-size: 70px;
  line-height: 78px;
  letter-spacing: -4px;
}

h2 {
  font-size: 50px;
  line-height: 60px;
}

h3 {
  font-size: 35px;
  line-height: 45px;
}

h4 {
  font-size: 40px;
  line-height: 50px;
}

h6 {
  font-size: 22px;
  line-height: 32px;
}

p {
  font-family: 'Poppins', sans-serif;
  color: #000;
  font-size: 25px;
  line-height: 35px;
  font-weight: 400;
}

.tabs-btn ul {
  padding: 0;
  /* margin: auto; */
  background: white;
  /* width: auto; */
  display: inline-block;
  padding: 10px;
  /* box-shadow: 5px 10px 0 #000;
   */
  border: 1px solid;
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
    5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
    10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
  position: relative;
  z-index: 2;
  transform: scale(0.8);
}

.tabs-btn ul li {
  display: inline-block;
  cursor: pointer;
  list-style: none;
}

.tabs-btn ul li a {
  padding: 15px 26px;
  font-size: 16px;
  color: black;
  text-decoration: none;
  line-height: 20px;
  cursor: pointer;
  display: block;
  transition: 0.5s;
}

.tabs-btn ul li label {
  padding: 15px 26px;
  font-size: 20px;
  color: black;
  text-decoration: none;
  line-height: 20px;
  cursor: pointer;
  display: block;
  transition: 0.5s;
}

label.active {
  background: #d9453d;
  color: white !important;
}

a.active {
  background: #d9453d;
  color: white !important;
}

.tabs-btn ul li label:hover {
  background: #d9453d;
  color: white;
}

.tabs-btn ul li a:hover {
  background: #d9453d;
  color: white;
}

button.btn.btn-primary {
  border: 0;
  background: transparent;
  padding: 0;
  display: inline-block;
  margin-top: 80px;
}

button.btn.btn-primary:focus {
  outline: 0;
  box-shadow: none;
}

.graphics-btn {
  background: white;
  color: black;
  font-weight: bold;
  text-align: center;
  height: 92px;
  width: auto;
  font-family: 'Space Mono', monospace;
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
    5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
    10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
  border: 0;
  font-size: 42px;
  margin-top: 30px;
  padding: 0 40px;
}

.graphics-btn:focus {
  outline: 0;
}

/**********************************************************************************/

#perspectv-login-screen {
  background: #ffdb59;
  background-repeat: no-repeat;
  background-position: bottom center;
  padding-top: 60px;
  min-height: 100vh;
}

/* #perspectv-login-screen h1 {
  font-weight: bold;
  letter-spacing: -4px;
  margin-top: 40px;
} */
#perspectv-login-screen h1 {
  font-weight: bold;
  letter-spacing: -4px;
  font-size: 3rem;
  margin-top: 40px;
}

.login-module-heading {
  font-size: 70px;
  font-weight: bold;
  line-height: 78px;
  letter-spacing: -4px;
  margin-bottom: 30px;
  margin-top: 90px;
  line-height: 2.6rem;
}

p.subtitle-text {
  font-size: 28px;
  font-style: normal;
}

/****************************** discover personaltiy main screen **************/

#discover-personality-start {
  background: #00c5c6;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
}

.discover-main-screen-cnt {
  padding-top: 50px;
  position: relative;
  z-index: 2;
}

#discover-personality-start:before {
  width: 1920px;
  height: 288px;
  background: url('../assets/images/discover-footer-graphics.png');
  background-size: cover;
  content: '';
  position: absolute;
  left: 0;
}

.discover-main-screen-cnt h6 {
  font-size: 55px;
  font-weight: bold;
  letter-spacing: -4px;
  line-height: 63px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.discover-main-screen-cnt a {
  display: inline-block;
  margin-top: 35px;
}

.login-pop-step-1 {
  background: url('../assets/images/login-pop-step-1-bg.png'), #d9453d;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.login-pop-step-1-jp {
  background: url('../assets/images/login-pop-step-1-bg.png'), #86cbff;
  background-size: contain;
  /* background-position: bottom; */
  background-position: center 263% !important;
  background-repeat: no-repeat;
}

.login-pop-step-2-jp {
  background: url('../assets/images/discover-footer-graphics.png'), #86cbff;
  background-size: contain;
  /* background-position: bottom; */
  background-position: center bottom !important;
  background-size: 140%;
  background-repeat: no-repeat;
}

.modal-skip-button-jp {
  background-color: #00c5c6;
  /* margin-bottom: 1px; */
}

.discover-main-screen-cnt-jp {
  padding-top: 50px;
  position: relative;
  z-index: 2;
  background-color: #86cbff;
}

a.skip-text {
  color: black;
  font-size: 20px;
  text-decoration: none;
}

h3.modal-header-text {
  font-size: 45px;
  font-weight: bolder;
  margin-bottom: 20px;
  font-weight: 900;
}

.background-match {
  background-color: #00c5c6;
}

fieldset.login-pop-step-1-jp-coloured {
  background-color: #4444f8;
}

.modal-close-stepfour.modal-header {
  background-color: #4444f8;
}

h3.modal-header-text-white {
  color: white;
  font-size: 36px;
  font-weight: bolder;
  margin-bottom: 20px;
  font-weight: 900;
}

a.skip-text-white {
  color: white;
  font-size: 20px;
  text-decoration: none;
}

.login-pop-step-2 {
  background: url('../assets/images/login-pop-step-2-bg.png'), #00cca3;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.login-pop-step-3 {
  background: url('../assets/images/login-pop-step-3-bg.png'), #00cca3;
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.login-pop-step-4 {
  background: #d9453d;
  /* Old browsers */
  background: -moz-linear-gradient(top, #d9453d 0%, #395ecc 100%, #7db9e8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #d9453d 0%, #395ecc 100%, #7db9e8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #d9453d 0%, #395ecc 100%, #7db9e8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

#discover-personality-steps .modal-content .modal-body .login-pop-step-4 button.next {
  background: white;
  border: 0;
  margin-top: 30px;
  font-family: 'Space Mono', monospace;
  font-size: 42px;
  font-weight: bold;
  width: auto;
  height: auto;
  padding: 20px 28px;
  line-height: 42px;
  box-shadow: 7px 10px 0 #000;
  position: relative;
}

/* .modal-text {
  text-align: left;
  font-size: 18px !important;
  margin-bottom: 1rem;
} */
.eye-icon-jp {
  padding-bottom: 183px;
  /* margin-bottom: -122px; */
}

.modal-text {
  text-align: center;
  font-weight: 500;
  color: black;
  font-size: 17px !important;
  margin-bottom: 1rem;
}

.modal-text-white {
  text-align: left;
  font-weight: 500;
  color: white;
  font-size: 17px !important;
  margin-bottom: 1rem;
}

.modal-content-wrapper {
  width: 89% !important;
  margin: auto;
}

.modal-content-wrap-two {
  width: 89% !important;
  margin: 0 auto;
}

.image-margin-top {
  margin-top: -181px !important;
}

.modal-content-wrap-four {
  width: 44% !important;
  margin: 0 auto;
}

.modal-two-image-hand {
  padding-bottom: 200px !important;
}

.py-3.color-match {
  background-color: #d9453d;
}

#discover-personality-steps .modal-content .modal-body .login-pop-step-4 .next:before {
  background: url('../assets/images/next-btn-left-elect.png');
  width: 112px;
  height: 111px;
  background-size: cover;
  content: '';
  position: absolute;
  left: -120px;
  top: -30px;
}

#discover-personality-steps .modal-content .modal-body .login-pop-step-4 .next:after {
  background: url('../assets/images/next-btn-right-elect.png');
  width: 112px;
  height: 111px;
  background-size: cover;
  content: '';
  position: absolute;
  right: -120px;
  top: -30px;
}

.login-pop-cnt {
  padding-top: 40px;
}

#discover-personality-steps .modal-header {
  position: absolute;
  z-index: 2;
  width: 100%;
  border: 0;
  top: 0;
  right: 0;
}

#discover-personality-steps .modal-content .modal-body {
  padding: 0;
}

#discover-personality-steps .modal-content .modal-body .form-card {
  padding: 30px 50px;
}

/* .login-pop-cnt h3 {
  font-weight: bold;
  letter-spacing: -4px;
  font-size: 39px;
  line-height: 47px;
  margin-top: 20px;
  margin-bottom: 15px;
  padding: 0 8%;
} */
.login-pop-cnt h3 {
  font-weight: bold;
  letter-spacing: -4px;
  font-size: 40px;
  line-height: 47px;
  /* font-weight: bold; */
  font-weight: 900;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px 12%;
}

.login-pop-cnt p {
  font-size: 14px;
  line-height: 20px;
}

#discover-personality-steps .modal-content .modal-body .next {
  width: 61px;
  height: 56px;
  background-size: cover;
  display: block;
  margin: auto;
}

#discover-personality-steps .modal-content .modal-body .btn-secondary {
  color: black;
  padding: 0;
  background: transparent;
  border: 0;
  outline: 0;
  margin: 10px 0 40px;
}

/*********************** discover personality setps screens *****************/

#discover-personality-steps {
  background: url('../assets/images/steps-top-graphics.png'),
    linear-gradient(354.65deg, #00cca3 -9.22%, #ffba0d 78.14%);
  /* background: #ecbb18; */
  /* background: linear-gradient(354.65deg, #00cca3 -9.22%, #ffba0d 78.14%); */
  /* background: -webkit-linear-gradient(top, #ecbb18 1%, #33c886 100%, #33c886 100%); */
  /* background: linear-gradient(to bottom, #ecbb18 1%, #33c886 100%, #33c886 100%); */
  /* background: -moz-linear-gradient(top, #ecbb18 1%, #33c886 100%, #33c886 100%);
  background: -webkit-linear-gradient(top, #ecbb18 1%, #33c886 100%, #33c886 100%);
  background: linear-gradient(to bottom, #ecbb18 1%, #33c886 100%, #33c886 100%); */
  padding-bottom: 40px;
  position: relative;
  height: 100% !important;
  background-repeat: no-repeat;

  background-position: center 0%;
}

#discover-personality-steps:after {
  /* background: url('../assets/images/steps-top-graphics.png'); */
  content: '';
  /* background: red; */
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  left: -1px;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.discover-personality-top-cnt {
  padding-top: 35px;
  position: relative;
  z-index: 1;
}

.discover-personality-top-cnt h1 {
  font-weight: bold;
  padding: 0 11%;
  margin-top: 20px;
}

.discover-personality-top-cnt p {
  margin-top: 35px;
  padding: 0 15%;
}

.discover-personality-top-cnt p strong {
  display: block;
  margin-top: 15px;
}

#msform nav {
  width: auto;
  display: inline-block;
}

#msform nav .nav-tabs {
  border: 0;
  width: auto;

  background: white;
  margin: auto;
  /* border: 1px solid; */
  padding: 12px;

  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
    5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
    10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
}

nav.border-nav {
  border-top: 1px solid;
}

nav.border-nav-left {
  border-top: 1px solid;
  border-left: 1px solid;
}

#msform nav .nav-tabs .nav-link {
  margin-bottom: 0;
  background: 0 0;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: 30px;
  line-height: 30px;
  padding: 12px 53px;
  color: black;
}

#msform nav .nav-tabs .nav-item.show .nav-link,
#msform nav .nav-tabs .nav-link.active {
  color: white;
  background-color: #d9453d;
}

#discover-personality-steps .card {
  background: transparent;
}

#msform fieldset {
  width: 100%;
  position: relative;
}

#msform fieldset .form-card div#nav-tabContent {
  background: white;

  /* padding: 15px; */
  /* margin-top: 50px; */
  /* display: flex;
  justify-content: center; */
}

.discover-check-cnt p {
  /* padding-top: 12px; */
  text-align: left;
  font-size: 20px;
  /* padding-left: 65px; */
  /* height: 50px; */
}

#msform {
  display: flex;
  /* overflow: hidden; */
  text-align: center;
  justify-content: center;
}

#msform .next {
  background: url('../assets/images/right-arrow.png');
  background-size: contain;
  width: 100px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  margin-top: 10px;
}

.imageContainer {
  margin-top: -157px;
}

/* login step button color header */
.modal-close-stepone.modal-header {
  background-color: #d9453d;
  padding-bottom: 195px;
}

.modal-close.modal-header {
  background-color: #00cca3;
}

.modal-close-stepone-jp {
  background-color: #86cbff;
  /* padding-bottom: 195px; */
}

.eye-icon-jp {
  padding-bottom: 108px;
}

/* //modal header css */

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  z-index: 999;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-btn {
  color: black;
  padding: 0;
  background: transparent;
  border: 0;
  font-size: 20px;
  outline: 0;
  margin: 10px 0 40px;
}

button.action-button {
  background: white;
  border: 0;
  margin-top: 30px;
  font-family: 'Space Mono', monospace;
  font-size: 42px;
  font-weight: bold;
  width: auto;
  height: auto;
  padding: 20px 28px;
  line-height: 42px;
  box-shadow: 7px 10px 0 #000;
  position: relative;
}

button.action-button::before {
  background: url(../assets/images/next-btn-left-elect.png);
  width: 112px;
  height: 111px;
  background-size: cover;
  content: '';
  position: absolute;
  left: -120px;
  top: -30px;
}

button.action-button::after {
  background: url(../assets/images/next-btn-right-elect.png);
  width: 112px;
  height: 111px;
  background-size: cover;
  content: '';
  position: absolute;
  right: -120px;
  top: -30px;
}

#msform fieldset:not(:first-of-type) {
  display: none;
}

.card {
  z-index: 1;
  border: none;
  position: relative;
}

.steps {
  font-size: 25px;
  color: black;
  font-weight: normal;
  text-align: center;
  line-height: 25px;
  margin-top: 15px;
  margin-bottom: 25px;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #673ab7;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: '\f13e';
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: '\f007';
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: '\f030';
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: '\f00c';
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #673ab7;
}

.progress {
  height: 20px;
  font-size: 0;
  background-color: white;
  border-radius: 0;
  width: 315px;
  padding: 2px;
  margin: auto;
}

.progress-bar {
  background-color: #5e43f8;
}

/* Customize the label (the container) */
.steps-checkbox {
  width: fit-content;
  display: block;
  position: relative;
  padding-left: 40px;

  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: 11px;
  color: black;
  padding-left: 60px;
}

/* Hide the browser's default checkbox */
.steps-checkbox input {
  display: none;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 34px;
  width: 42px;
  border-radius: 0;
  border: 1px solid #343f49;
  background-color: white;
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
    5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
    10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
}

.view-shadow {
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
    5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
    10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
}

/* On mouse-over, add a grey background color */
.steps-checkbox:hover input~.checkmark {
  background-color: gainsboro;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.steps-checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.steps-checkbox .checkmark:after {
  left: 17px;
  top: -14px;
  width: 13px;
  height: 41px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

/**************** Login page style ***************/

#login-page {
  background: url('../assets/images/top-graphics-yellow.png'), #d9453d;
  background-position: 0px -180px;
  min-height: 100vh;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 70px;
}

#login-page form {
  margin-top: 40px;
  /* padding: 0 24%; */
}

#login-page form input.form-control {
  display: block;
  height: 92px;
  border: 0;
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
    5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
    10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
  margin-bottom: 9px;
  width: 100%;
  max-width: 100%;
  text-align: center;
  font-size: 25px;
  color: black;
  border-radius: 0;
}

#login-page form .form-btn {
  background: #00c5c6;
  color: black;
  font-weight: bold;
  text-align: center;
  height: 92px;
  width: 100%;
  max-width: 100%;
  font-family: 'Space Mono', monospace;
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
    5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
    10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
  border: 0;
  font-size: 42px;
  margin-top: 30px;
}

#login-page form .form-btn:focus {
  outline: 0;
}

p.login-signup-txt {
  font-size: 20px;
  line-height: 30px;
  margin-top: 30px;
  display: block;
}

p.login-signup-txt strong {
  font-family: 'Space Mono', monospace;
  font-weight: bold;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

p.login-signup-txt strong:hover {
  color: #00c5c6;
  text-decoration: underline !important;
  cursor: pointer;
}

.login-checkbox {
  margin-bottom: 60px;
  display: block;
  padding: 0 16%;
  text-align: center;
  margin-top: 40px;
}

#login-page form h6 {
  color: #111;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: 0;
  display: block;
  margin-top: 70px;
  margin-bottom: 30px;
}

#login-page form h3 {
  font-size: 35px;
  line-height: 51px;
  font-weight: bold;
  color: white;
}

.otp-input {
  display: flex;
  height: 92px;
  border: 0;
  box-shadow: 12px 15px 0 #000;
  /* color: green; */
  border-radius: 0;
  margin-bottom: 9px;
  width: 100%;
  text-align: center;
  background: white;
  padding: 15px;
  align-items: center;
  justify-content: space-around;
}

.otp-input input.form-control {
  /* border: 0; */
  border-bottom: 7px solid #000 !important;
  box-shadow: none !important;
  margin: 0 7px !important;
  height: 60px !important;
  /* background: transparent; */
  font-weight: bold;
  font-weight: bold;
}

/************* signup page ***********/

#signup-page {
  background: url('../assets/images/top-graphics-yellow.png'), #fff;
  /* background-position: -369px -464px; */
  background-position: -369px -504px;
  background-size: cover;
  min-height: 100vh;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 70px;
}

#signup-page form {
  margin-top: 100px;
}

.signup-input-bx {
  position: relative;
  margin-bottom: 30px;
}

.signup-input-bx label {
  position: absolute;
  top: -8px;
  left: 18px;
  background: white;
  font-size: 12px;
  color: #717171;
  padding: 0 8px;
  z-index: 9999;
}

.signup-input-bx input {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  height: 68px;
  width: 100%;
  color: black;
  font-size: 15px;
  padding: 12px;
  z-index: 1;
}

.signup-input-bx input:focus {
  box-shadow: none;
  outline: 0;
  border: 1px solid #32c5c6;
}

.signup-input-bx:focus label {
  color: #32c5c6;
}

.form-btn {
  background: #00c5c6;
  color: black;
  font-weight: bold;
  text-align: center;
  height: 92px;
  max-width: 385px;
  width: 385px;
  font-family: 'Space Mono', monospace;
  box-shadow: 12px 15px 0 #000;
  border: 0;
  font-size: 42px;
  margin-top: 30px;
}

#signup-page form .form-btn:focus {
  outline: 0;
}

#signup-page form p {
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: black;
}

#signup-page form p a {
  font-weight: bold;
  color: #00c5c6;
  text-decoration: none;
}

#signup-page .login-signup-txt {
  color: black;
  font-size: 22px;
  line-height: 30px;
}

#signup-page .login-signup-txt strong {
  color: black;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.signup-verification-col p {
  font-size: 0.85rem !important;
  /* line-height: 26px !important; */
  margin-bottom: 5px !important;
  color: black;
}

.form-btn {
  background: #00c5c6;
  color: black;
  font-weight: bold;
  text-align: center;
  height: 92px;
  max-width: 385px;
  width: 300px;
  font-family: 'Space Mono', monospace;
  /* box-shadow: 12px 15px 0 #000; */
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
    5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
    10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
  border: 0;
  font-size: 42px;
  margin-top: 30px;
}

.loading-btn {
  background: #00c5c6;
  color: black;
  font-weight: bold;
  text-align: center;
  height: 92px;
  width: 100%;
  font-family: 'Space Mono', monospace;
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
    5px 5px 0px #000, 6px 6px 0px #000;
  border: 0;
  font-size: 42px;
  margin-top: 30px;
}

/************ get started page *****/

#get-started-page {
  background: #4344f8;
  min-height: 100vh;
  padding-top: 50px;
}

#get-started-page h6 {
  font-size: 35px;
  line-height: 52px;
  color: #f1da12;
  font-weight: bold;
  margin-top: 60px;
}

.get-strarted-button p a {
  color: black;
  text-decoration: none;
}

#splash-screen {
  height: 100vh;
  background: #d9453d;
}

.splash-screen-cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/******************* design speed screen ***/

#design-speed-screen {
  background: url('../assets/images/bottom-skyblu-graphics.png'), #87ccff;
  background-repeat: no-repeat;
  background-position: center 181%;
  padding-top: 30px;
  min-height: 100vh;
  padding-bottom: 50px;
}

#design-quality-screen {
  background: url('../assets/images/discover-footer-graphics.png'), #00c5c6;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-top: 60px;
  min-height: 100vh;
  padding-bottom: 50px;
}

.discover-main-screen-cnt a {
  color: black;
  text-decoration: none;
  font-size: 18px;
}

.signup-verification-col p {
  text-transform: capitalize !important;
  margin-bottom: 25px;
}

.signup-verification-col .wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.signup-verification-col .wrapper .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 10px;
  cursor: pointer;
  padding: 0 10px;
  transition: all 0.3s ease;
}

.signup-verification-col .wrapper .option .dot {
  height: 22px;
  width: 22px;
  background: transparent;
  border-radius: 100%;
  position: relative;
  border: 2px solid #243f55;
}

.signup-verification-col .wrapper .option div {
  text-align: left;
  padding-left: 18px;
}

.signup-verification-col .wrapper .option div h6 {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
  color: #243f55;
  font-family: 'Poppins', sans-serif;
  line-height: 20px;
  margin-bottom: 5px;
}

.option div h6 img {
  margin-right: 3px;
  width: 15px;
}

.signup-verification-col {
  margin-bottom: 38px;
  padding: 0 7%;
}

.signup-verification-col .wrapper .option div p {
  font-size: 0.65rem !important;
  /* line-height: 20px !important; */
  color: #757575 !important;
  text-transform: capitalize !important;
  margin-bottom: 0;
}

.signup-verification-col .wrapper .option .dot::before {
  position: absolute;
  content: '';
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  background: #03c6c7;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}

.signup-verification-col input[type='radio'] {
  display: none;
}

.signup-verification-col #option-1:checked:checked~.option-1 .dot,
.signup-verification-col #option-2:checked:checked~.option-2 .dot {
  background: #fff;
  border-color: #03c6c7;
}

.signup-verification-col #option-1:checked:checked~.option-1 .dot::before,
.signup-verification-col #option-2:checked:checked~.option-2 .dot::before {
  opacity: 1;
  transform: scale(1);
}

.signup-verification-col .wrapper .option span {
  font-size: 20px;
  color: #808080;
}

.signup-verification-col #option-1:checked:checked~.option-1 span,
.signup-verification-col #option-2:checked:checked~.option-2 span {
  color: #fff;
}

/************ role option style ********/

#job-role-steps {
  /* background: url('../assets/images/dahsbarod-bg.jpg'), #fff; */
  background: url('../assets/images/job-role-step-bg.png'), #ffd578;
  background-repeat: no-repeat;
  /* background-position: center 254%; */
  background-position: center bottom;
  background-size: contain;
  padding-top: 18px;
  min-height: 100vh;
  padding-bottom: 26px;
}

.bg-position-1 {
  background-position: center 254% !important;
}

#job-role-steps .card {
  background: transparent;
}

#job-role-steps .form-card h3 {
  font-weight: bold;
  font-size: 55px;
  line-height: 63px;
  letter-spacing: -4px;
  margin-top: 30px;
  margin-bottom: 40px;
}

#job-role-steps .progress {
  border: 1px solid #bfbfbf;
}

.role-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.role-wrapper .role-col {
  width: 33.33%;
  padding: 0 10px;
}

.role-wrapper .inputGroup {
  background-color: #fff;
  display: block;
  margin: 7px 0;
  position: relative;
  overflow: hidden;
  /* box-shadow: 12px 15px 0 #000; */
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
    5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
    10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
  border: 1px solid #000;
  color: black;
}

.role-wrapper .inputGroup input {
  position: absolute;
  top: -16px;
}

.role-wrapper .inputGroup label {
  padding: 15px 10px;
  width: 100%;
  display: block;
  text-align: center;
  /* height: 205px; */
  color: #000;
  cursor: pointer;
  position: relative;
  margin: 0px !important;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  font-size: 16px;
}

.feedbacks-option ul li a.active {
  background: #5e43f8 !important;
}

.role-wrapper .inputGroup input:checked~label {
  color: #fff;
  background: #5e43f8;
}

.role-wrapper.red-radio-btn .inputGroup input:checked~label {
  background: #d9453d;
}

.role-wrapper.skyblue-radio-btn .inputGroup input:checked~label {
  background: #00c5c6;
}

.enter-job-role {
  margin-top: 55px;
  padding: 0 15%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.enter-job-role label.steps-checkbox {
  margin-bottom: 40px;
  margin-top: 24px;
}

.enter-job-role h5 {
  font-size: 41px;
  font-weight: bold;
  line-height: 49px;
  letter-spacing: -4px;
  margin-bottom: 30px;
}

.enter-job-role input {
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  font-size: 16px;
  color: black;
  padding: 14px 20px;
  width: 100%;
  text-align: center;
}

.enter-job-role input::placeholder {
  color: #8b8b8b;
  font-size: 16px;
}

.range-slider {
  width: 100%;
  padding: 0 25%;
  margin-top: 60px;
  margin-bottom: 15px;
}

.range-slider .slidecontainer {
  width: 100%;
}

.range-slider .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #9ee9e9;
  outline: none;
  border-radius: 5px;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.range-slider .slider:hover {
  opacity: 1;
}

.range-slider .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 21px;
  height: 21px;
  background: #d9453d;
  cursor: pointer;
  border-radius: 100%;
}

.range-slider .slider::-moz-range-thumb {
  width: 21px;
  height: 21px;
  background: #d9453d;
  cursor: pointer;
  border-radius: 100%;
}

.range-slider p {
  font-size: 55px;
  font-weight: bold;
  line-height: 74px;
  font-family: 'Space Mono', monospace;
  position: relative;
  width: auto;
  display: inline-block;
}

.range-slider p img {
  position: absolute;
  left: -37px;
  top: 24px;
}

#skills-steps {
  background: url('../assets/images/hard-skill-graphics-bg.png'), #fff;
  background-repeat: no-repeat;
  background-position: center top;
  padding-top: 18px;
  min-height: 100vh;
  padding-bottom: 26px;
}

#soft-skills-steps {
  background: url('../assets/images/soft-skill-graphics-bg.png'), #fff;
  background-repeat: no-repeat;
  background-position: center top;
  padding-top: 18px;
  min-height: 100vh;
  padding-bottom: 26px;
}

.card {
  background: transparent;
}

#skills-steps h3,
#soft-skills-steps h3 {
  font-weight: bold;
  font-size: 55px;
  letter-spacing: -4px;
  line-height: 62px;
  margin-top: 20px;
  margin-bottom: 100px;

  color: white;
}

#soft-skills-steps h3 {
  margin-top: 50px;
}

.enter-hard-skills h5 {
  font-size: 23px;
  font-weight: bold;
  line-height: 32px;
  letter-spacing: -2px;
  margin-bottom: 30px;
}

.enter-hard-skills h5 strong {
  color: #00c5c6;
}

.enter-hard-skills input {
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  font-size: 19px;
  color: black;
  padding: 14px 20px;
  width: 100%;
  text-align: center;
  font-family: 'Space Mono', monospace;
}

.enter-hard-skills input::placeholder {
  color: #8b8b8b;
}

.enter-hard-skills {
  padding: 0 20%;
}

.exp-range-slider {
  margin-top: 50px;
  margin-bottom: 25px;
  clear: both;
  display: block;
  overflow: hidden;
}

.skill-range-slider {
  width: 50%;
  padding: 0 25px 0 15px;
  margin-top: 25px;
  margin-bottom: 15px;
  float: left;
}

.skill-range-slider .slidecontainer {
  width: 100%;
}

.skill-range-slider .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #9ee9e9;
  outline: none;
  border-radius: 5px;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.skill-range-slider .slider:hover {
  opacity: 1;
}

.skill-range-slider .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 21px;
  height: 21px;
  background: #d9453d;
  cursor: pointer;
  border-radius: 100%;
}

.skill-range-slider .slider::-moz-range-thumb {
  width: 21px;
  height: 21px;
  background: #d9453d;
  cursor: pointer;
  border-radius: 100%;
}

.skill-range-slider p {
  font-size: 55px;
  font-weight: bold;
  line-height: 74px;
  font-family: 'Space Mono', monospace;
  position: relative;
  width: auto;
  display: inline-block;
}

.skill-range-slider p {
  font-size: 21px;
  font-weight: 400;
  line-height: 29px;
  font-family: 'Space Mono', monospace;
  position: relative;
  width: auto;
  display: inline-block;
  margin-top: 17px;
  text-align: left;
  width: 100%;
}

.skill-range-slider .slidecontainer h6 {
  font-weight: bold;
  font-size: 21px;
  line-height: 29px;
  text-align: left;
}

#feel-suite-best {
  background: url('../assets/images/yellow-top-graphics-bg.png'), #fff;
  background-repeat: no-repeat;
  background-position: center top;
  padding-top: 18px;
  min-height: 100vh;
  padding-bottom: 26px;
}

.suite-best-wrapper {
  max-width: 900px;
  margin: auto;
}

.suite-best-wrapper .inputGroup {
  background-color: #fff;
  display: block;
  margin: 7px 0;
  position: relative;
  overflow: hidden;
  /* box-shadow: 12px 15px 0 #000; */
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
    5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
    10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
  border: 1px solid #000;
  color: black;
  width: 100%;
  height: 80%;
}

.suite-best-wrapper .inputGroup input {
  position: absolute;
  top: -16px;
}

.suite-best-wrapper .inputGroup label {
  padding: 15px 10px;
  width: 100%;
  display: block;
  text-align: center;
  color: #000;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  font-size: 18px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.suite-best-wrapper .inputGroup input:checked~label {
  color: #000;
  background: #87ccff;
  height: 100%;
}

.yellow-hvr-checkbox .inputGroup input:checked~label {
  color: #000;
  background: #ffdb59;
  height: 100%;
}

.suite-best-wrapper .col {
  padding: 0 2px;
  width: 20%;
  max-width: 20%;
  flex: auto;
}

#feel-suite-best h3 {
  font-weight: bold;
  letter-spacing: -4px;
  font-size: 55px;
  line-height: 63px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0 15%;
}

#feel-suite-best .suite-best-wrapper {
  margin-bottom: 70px;
}

/* .upload-your-profile {
  margin-top: 150px;
} */

.payment-plan {
  margin-top: 150px;
}

.company-job {
  margin-top: 70px;
}

.main-menu {
  margin-top: 70px;
}

.profile-img {
  margin-top: 50px;
}

.profile-image-container {
  width: 200px;
  height: 200px;
  margin: 50px auto;
  border-radius: 50%;
  border: 3px solid black;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}

.profile-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-img .file {
  position: relative;
  display: inline-block;
  top: 60px;
  left: -23px;
}

.profile-img .file input {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
  width: 30px;
}

.upload-cv-col {
  padding: 0 25%;
  margin: 30px 0 55px;
}

.upload-cv-col .custom-file {
  display: flex;
  align-items: center;
  border: 2px solid #000;
  padding: 8px;
  border-radius: 5px;
  width: 100%;
  justify-content: space-between;
}

.custom-file div {
  position: relative;
}

.upload-your-profile h4 {
  font-size: 37px;
  font-weight: 500;
}

.custom-file div input {
  position: absolute;
  visibility: hidden;
}

.custom-file div p {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0;
}

.upload-your-profile h6 {
  font-size: 37px;
  line-height: 43px;
  margin-top: 40px;
}

.custom-file .custom-file-label {
  width: 100%;
  position: absolute;
  text-align: right;
  right: 7px;
}

.personal-bio-cnt {
  margin-bottom: 50px;
}

.upload-your-profile h5 {
  text-align: left;
  font-size: 19px;
  font-weight: 600;
  margin-top: 70px;
  margin-bottom: 30px;
  font-family: 'Poppins', sans-serif;
}

.personal-bio-cnt h6 {
  font-size: 19px;
  font-weight: 600;
  text-align: left;
  margin-top: 0;
  line-height: 28px;
  margin-bottom: 0px;
  font-family: 'Poppins', sans-serif;
}

.personal-bio-cnt p {
  font-size: 14px;
  text-align: left;
  margin: 0;
  line-height: 20px;
}

#congrts-page {
  background: #4344f8;
  min-height: 100vh;
  padding-top: 50px;
}

#congrts-page h6 {
  font-size: 44px;
  line-height: 52px;
  color: #fff;
  font-weight: bold;
  margin-top: 60px;
}

#congrts-page p {
  font-family: 'Space Mono', monospace;
  font-size: 18px;
  line-height: 26px;
  color: white;
  text-align: center;
}

#enhanced-profile {
  background: #d9453d;
  /* Old browsers */
  background: -moz-linear-gradient(top, #d9453d 0%, #395ecc 100%, #7db9e8 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #d9453d 0%, #395ecc 100%, #7db9e8 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #d9453d 0%, #395ecc 100%, #7db9e8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  min-height: 100vh;
  padding: 50px 0;
}

#enhanced-profile button.next {
  margin-top: -3% !important;
  background: white;
  border: 0;
  margin-top: 30px;
  outline: none;
  font-family: 'Space Mono', monospace;
  font-size: 42px;
  font-weight: bold;
  width: auto;
  height: auto;
  padding: 20px 28px;
  line-height: 42px;
  box-shadow: 7px 10px 0 #000;
  position: relative;
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
    5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
    10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
}

#enhanced-profile .next:before {
  background: url('../assets/images/next-btn-left-elect.png');
  width: 112px;
  height: 111px;
  background-size: cover;
  content: '';
  position: absolute;
  left: -120px;
  top: -30px;
}

#enhanced-profile .next:after {
  background: url('../assets/images/next-btn-right-elect.png');
  width: 112px;
  height: 111px;
  background-size: cover;
  content: '';
  position: absolute;
  right: -140px;
  top: -30px;
}

#red-graphics-bg {
  background: url('../assets/images/soft-skill-graphics-bg.png'), #fff;
  background-repeat: no-repeat;
  background-position: center top -45px;
  padding-top: 18px;
  min-height: 100vh;
  padding-bottom: 26px;
}

.upload-your-video h3 {
  font-size: 55px;
  line-height: 63px;
  color: white;
  text-align: center;
  margin-top: 50px;
  padding: 0 20%;
}

.upload-your-video h4 {
  font-size: 25px;
  line-height: 33px;
  color: #000;
  margin-top: 120px;
  margin-bottom: 90px;
}

.add-payment-method {
  margin-top: 120px;
}

.add-payment-method button.accordion-button {
  padding: 5px 10px;
}

.add-payment-method button.accordion-button {
  padding: 12px 10px;
  height: auto;
  min-height: auto;
  font-size: 18px;
  line-height: 26px;
  border: 2px solid #000;
  border-radius: 5px;
  font-family: 'Space Mono', monospace;
  font-weight: 600;
}

.add-payment-method button.accordion-button:not(.collapsed) {
  color: #000;
  background-color: white;
  box-shadow: none;
}

.add-payment-method .accordion-item {
  border: 0;
  margin-bottom: 25px;
}

.add-payment-method .accordion-button:not(.collapsed)::after {
  background: url('../assets/images/subtract-icon.svg');
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  /*    transform: rotate(-180deg);*/
  content: '';
  background-repeat: no-repeat;
}

.add-payment-method .accordion-button::after {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin-left: auto;
  content: '';
  background: url('../assets/images/plus-icon.svg');
  background-repeat: no-repeat;
  background-size: 18px 18px;
  transition: transform 0.2s ease-in-out;
}

.add-payment-method .accordion .accordion-body ul {
  display: block;
  padding: 0 20px;
  margin: 10px 0;
}

.add-payment-method .accordion .accordion-body ul li {
  list-style: none;
  display: block;
  text-align: left;
  margin: 20px 0;
}

.add-payment-method .accordion .accordion-body ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  text-decoration: none;
  color: #000;
  font-weight: 600;
  line-height: 22px;
}

.add-payment-method .accordion .accordion-body ul li a img {
  width: 20px;
  height: 20px;
}

.add-payment-method .accordion .accordion-body {
  padding: 7px 15px 0px;
}

/**************** form page style **********************************/

#form-page {
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 70px;
}

.red-graphics-top {
  background: url('../assets/images/soft-skill-graphics-bg.png'), #fff;
  background-position: 0px -100px;
  background-repeat: no-repeat;
}

#form-page form {
  margin-top: 100px;
}

#form-page form .signup-input-bx {
  position: relative;
  margin-bottom: 30px;
}

#form-page form .signup-input-bx label {
  position: absolute;
  top: -8px;
  left: 18px;
  background: white;
  font-size: 12px;
  color: #717171;
  padding: 0 8px;
}

#form-page form .signup-input-bx input {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  height: 68px;
  width: 100%;
  color: black;
  font-size: 15px;
  padding: 12px;
}

#form-page form .signup-input-bx input:focus {
  box-shadow: none;
  outline: 0;
  border: 1px solid #32c5c6;
}

#form-page form .signup-input-bx:focus label {
  color: #32c5c6;
}

#form-page form .form-btn {
  background: #00c5c6;
  color: black;
  font-weight: bold;
  text-align: center;
  height: 92px;
  max-width: 385px;
  width: 385px;
  font-family: 'Space Mono', monospace;
  box-shadow: 12px 15px 0 #000;
  border: 0;
  font-size: 42px;
  margin-top: 30px;
}

#form-page form .form-btn:focus {
  outline: 0;
}

#form-page form p {
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: black;
}

#form-page form p a {
  font-weight: bold;
  color: #00c5c6;
  text-decoration: none;
}

#form-page .login-signup-txt {
  color: black;
  font-size: 0.7rem;
  line-height: 30px;
}

#form-page .login-signup-txt strong {
  color: black;
  text-decoration: underline;
  font-weight: bold;
}

/************ get started page *****/

.add_card-checkbox .form-check.form-switch label {
  float: left;
}

.add_card-checkbox .form-check.form-switch {
  padding: 0;
  font-size: 18px;
  line-height: 26px;
}

.add_card-checkbox .switch {
  position: relative;
  width: 100%;
  text-align: left;
}

.add_card-checkbox .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.add_card-checkbox .slider {
  position: absolute;
  cursor: pointer;
  top: 3px;
  right: 36%;
  bottom: 0;
  background-color: #9ee9e9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 44px;
  height: 18px;
}

.add_card-checkbox .slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: 0px;
  bottom: -2px;
  background-color: #007477;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.add_card-checkbox input:checked+.slider {
  background-color: #2196f3;
}

.add_card-checkbox input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

.add_card-checkbox input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.add_card-checkbox .slider.round {
  border-radius: 34px;
}

.add_card-checkbox .slider.round:before {
  border-radius: 50%;
}

.payment-success h4 {
  margin-top: 170px;
  line-height: 63px;
  font-size: 55px;
  font-weight: bold;
}

/**************** Dashboard screen *******/

.dashboard-header {
  background: #ffd578;
  /* padding: 35px 0; */
  position: relative;
}

.dashboard-bg {
  background: url('../assets/images/dahsbarod-bg1.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.talent-list-btn {
  width: 300px;
  font-family: 'Space Mono';
  letter-spacing: -1px;
}

.msg-btn button {
  width: 200px;
}

@media screen and (max-width: 768px) {
  .dashboard-header {
    background: #ffd578;
    /* padding: 15px 0; */
    position: relative;
  }

  .msg-btn button {
    width: 150px;
    font-size: 16px !important;
  }

  .landing-current-job-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .talent-list-btn {
    width: 100%;
    margin-bottom: 5rem;
  }

  .no-records-section {
    min-height: 300px;
  }
}

/* .dashboard-header:before {
  background: url("../assets/images/dashboard-header-left-vector.png");
  background-repeat: no-repeat;
  position: absolute;
  left: -25%;
  bottom: -297px;
  content: "";
  width: 858px;
  height: 305px;
  z-index: -1;
} */

/* .dashboard-header:after {
  background: url("../assets/images/dashboard-header-right-vector.png");
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  bottom: -77px;
  content: "";
  width: 227px;
  height: 102px;
  z-index: -1;
} */

.header-left-cnt {
  display: flex;
  justify-content: end;
  align-items: center;
}

.dashboard-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.dashboard-nav .notification-bell {
  position: relative;
  margin-right: 30px;
}

.dashboard-nav .notification-bell span {
  position: relative;
  color: #000;
  font-size: 25px;
  line-height: 25px;
  top: 5px;
  text-decoration: none;
}

.dashboard-nav .notification-bell span:after {
  width: 13px;
  height: 13px;
  /* background: #d9453d; */
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  /* border: 1px solid #ffd578; */
  border-radius: 100%;
}

.dashboard-nav .notification-dot span:after {
  width: 13px;
  height: 13px;
  background: #d9453d;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #ffd578;
  border-radius: 100%;
}

#dashboard-cnt-area {
  padding-top: 90px;
  padding-bottom: 60px;
  min-height: 85vh;
}

.mircroshoft-logo img {
  float: right;
}

.dashboard-sidebar ul {
  padding: 0;
  margin: 0;
}

.dashboard-sidebar ul li {
  display: block;
  list-style: none;
  margin-bottom: 10px;
}

.dashboard-sidebar ul li a {
  font-size: 20px;
  line-height: 28px;
  padding: 20px 20px;
  width: 100%;
  background: #1ec991;
  box-shadow: 8px 13px 0 #000;
  display: block;
  text-decoration: none;
  color: black;
  text-align: center;
}

.fx-profile-card {
  border: 2px solid #cccccc;
  padding: 15px 20px;
  text-align: center;
  position: relative;
  margin-bottom: 25px;
}

.fx-profile-card .fx-card-cnt {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
}

.fx-profile-card .fx-card-cnt .fx-profile-graph {
  border: 2px solid red;
  height: 63px;
  width: 63px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fx-profile-card .fx-card-cnt .fx-profile-graph span {
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: red;
}

.fx-profile-card .fx-card-cnt .fx-profile-text {
  text-align: left;
  margin-left: 15px;
}

.fx-profile-card .fx-card-cnt .fx-profile-text h6 {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
}

.fx-profile-card .fx-card-cnt .fx-profile-text p {
  color: #636161;
  font-size: 15px;
  line-height: 19px;
  margin-top: 8px;
  margin-bottom: 0;
}

.fx-profile-card a {
  position: absolute;
  right: 20px;
  top: 15px;
  text-decoration: none;
  color: red;
  font-size: 25px;
  width: 65px;
}

.dashboard-footer {
  margin-top: 311px; /* This is the height of the bird*/
  background: black;
  padding: 15px 0;
  /* position: absolute;
  bottom: 0%; */
}

.dashboard-footer p {
  color: white;
  font-size: 15px;
  line-height: 22px;
  margin: 0;
}

.eagle-man-col {
  position: relative;
}

.eagle-man-col img {
  position: absolute;
  width: 386px;
  height: 327px;
  left: 0;
  bottom: -15px;
}

.current-matches-heading h6 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.col-lg-12.col-sm-12.col-xs-12.mircroshoft-cnt {
  padding-top: 100px;
}

.mircroshoft-heading h4 {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  line-height: 32px;
  padding-bottom: 10px;
  text-align: start;
  font-weight: bold;
  margin-bottom: 0;
}

.mircroshoft-heading h4 i {
  margin-left: 15px;
  text-align: start;
  padding-bottom: 10px;
  color: red;
}

.mircroshoft-heading h6 {
  font-family: 'Poppins', sans-serif;
  font-size: 19px;
  padding-bottom: 10px;
  text-align: start;
  line-height: 26px;
  font-weight: 600;
}

.mircroshoft-heading p {
  font-size: 17px;
  line-height: 23px;
  font-weight: 500;
}

.mircroshoft-cnt h5 {
  font-family: 'Poppins', sans-serif;
  font-size: 19px;
  text-align: start;
  line-height: 26px;
  font-weight: 600;
}

.mircroshoft-cnt p {
  font-size: 14px;
  text-align: start;
  line-height: 21px;
}

.like-deslike-col {
  padding-bottom: 200px;
}

.like-deslike-btns {
  display: flex;
  justify-content: flex-start;
}

.like-deslike-btns a {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.like-btns {
  background: #1ec991;
  display: inline-block;
  width: 126px;
  height: 86px;
  border: 1px solid;
  box-shadow: 9px 12px 0 #000;
  margin-right: 20px;
  font-size: 50px;
  text-align: center;
  color: black;
  text-decoration: none;
}

.dislike-btns {
  background: #d9453d;
  display: inline-block;
  width: 126px;
  height: 86px;
  border: 1px solid;
  box-shadow: 9px 12px 0 #000;
  margin-right: 20px;
  font-size: 50px;
  text-align: center;
  color: black;
  text-decoration: none;
}

.like-deslike-btns a span {
  font-size: 12px;
  color: #000;
}

.like-deslike-btns a:hover {
  background: #ffd578;
  color: #000;
}

/************ feedback*******************/

.feedback-heading h2 {
  font-size: 40px;
  line-height: 46px;
  font-weight: bold;
  margin-top: 20px;
}

.feedback-heading p {
  font-size: 14px;
  line-height: 21px;
}

.feedbacks-option {
  margin-top: 20px;
}

.feedbacks-option ul {
  padding: 0;
  margin: 0;
  display: table-footer-group;
}

.feedbacks-option ul li {
  display: inline-block;
  width: 50%;
  list-style: none;
  margin-bottom: 16px;
  padding: 0 3px;
}

.feedbacks-option ul li a {
  display: block;
  font-size: 16px;
  box-shadow: 7px 12px 0 #000;
  padding: 15px 20px;
  border: 1px solid #000;
  text-align: center;
  text-decoration: none;
  color: #000;
  transition: 0.3s;
  background: white;
}

.feedbacks-option ul li a:hover {
  background: #5e43f8;
  color: white;
}

.feedback-action-btn a {
  display: block;
  margin-top: 20px;
  text-align: left;
  color: black;
  text-decoration: none;
}

.feedback-action-btn a span {
  padding: 5px 40px;
}

.microsoft-cnt-dtl .signup-input-bx {
  position: relative;
  margin-bottom: 30px;
}

.microsoft-cnt-dtl .signup-input-bx label {
  position: absolute;
  top: -8px;
  left: 18px;
  background: white;
  font-size: 12px;
  color: #717171;
  padding: 0 8px;
}

.microsoft-cnt-dtl .signup-input-bx input {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  height: 68px;
  width: 100%;
  color: black;
  font-size: 15px;
  padding: 12px;
}

.microsoft-cnt-dtl h3 {
  color: black;
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 15px;
}

.message-btn {
  text-align: right;
  margin-top: 38px;
}

/************ recent **********/

.recent-page-heading h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.recent-page-heading a {
  box-shadow: 7px 12px 0 #000;
  padding: 15px 40px;
  border: 1px solid #000;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: black;
  background: #ffdb59;
  text-decoration: none;
}

.recent-page-heading-jp a {
  /* box-shadow: 7px 12px 0 #000; */
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
    5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
    10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
  padding: 15px 40px;
  border: 1px solid #000;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: black;
  background: #1ec991;
  text-decoration: none;
}

.recent-profile {
  border: 2px solid #cccccc;
  padding: 20px;
  background: white;
  min-height: 185px;
  margin-bottom: 30px;
}

.recent-profile .recent-profile-img {
  margin-right: 10px;
  display: inline-block;
  width: 65px;
  float: left;
}

.recent-profile-text {
  display: inline-block;
  padding-left: 10px;
  width: 70%;
}

.recent-profile-text h5 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 30px;
}

.recent-profile-text span {
  font-size: 14px;
  line-height: 20px;
}

.recent-profile-text p {
  color: #707070;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

#password-changed-screen {
  background: url('../assets/images/top-graphics-yellow.png'), #fff;
  background-position: 0px -180px;
  min-height: 100vh;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 70px;
}

.mircroshoft-cnt h5 a img {
  position: relative;
  margin-left: 3px;
  top: -1px;
}

.payment-plan-col {
  padding: 60px 30px;
  background: #f1f1f1;
  border-radius: 15px;
  transition: 0.3s;
  cursor: pointer;
}

.payment-plan-col-active {
  background: #00cca3;
}

.payment-plan-col:hover {
  background: #00cca3;
}

.payment-plan-col h5 {
  font-size: 22px;
  font-weight: bold;
  color: black;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 2px;
}

.current-job-col {
  border: 2px solid #cccccc;
  padding: 20px;
  background: white;
  min-height: 100px;
  /* justify-self: auto; */
  /* align-content: space-between; */
  display: flex;
}



.current-job-col-text h5 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
  text-align: left;
  line-height: 30px;
}

.current-job-col-text p {
  font-size: 14px;
  line-height: 30px;
  width: 100%;
  text-align: left;
}

.agent-profile-cnt {
  display: flex;
  align-items: center;
}

.agent-profile-img img {
  max-width: 110px;
}

.agent-profile-img {
  position: relative;
}

.agent-profile-img span {
  background: #1ec991;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 26px;
  color: white;
  position: absolute;
  left: 80px;
  bottom: 0px;
}

.agent-profile-img {
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.agent-profile-text {
  display: inline-block;
}

.agent-profile-text h5 {
  color: black;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 0;
}

.agent-profile-text h6 {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif;
}

.agent-profile-text p {
  margin: 0;
  font-size: 17px;
  line-height: 23px;
  font-weight: 500;
}

.agent-profile-complete {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.agent-profile-complete p {
  border: 3px solid red;
  font-size: 25px;
  font-weight: bold;
  color: red;
  width: 70px;
  height: 70px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-right: 20px;
}

.agent-profile-complete a {
  color: red;
  font-size: 25px;
  text-decoration: none;
}

.reason-rejection-accordions {
  max-width: 444px;
}

.reason-rejection-accordions button.accordion-button {
  padding: 5px;
  background: #f1f1f1;

  display: flex;
  justify-content: space-between;
}

.reason-rejection-accordions button.accordion-button p {
  padding: 6px 10px;
  background: #f1f1f1;
  font-size: 15px;
  font-weight: bold;
  color: black;
  line-height: 23px;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.reason-rejection-accordions .accordion-button:not(.collapsed)::after {
  display: none;
}

.reason-rejection-accordions .accordion-button::after {
  display: none;
}

.reason-rejection-accordions .accordion-button span {
  font-size: 15px;
  background: #00c5c6;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  color: black;
  font-weight: bold;
}

.reason-rejection-accordions .accordion-body ul {
  padding: 0;
  margin: 0;
}

.reason-rejection-accordions .accordion-body ul li {
  list-style: none;
  padding: 6px 0;
  display: flex;
  justify-content: space-between;
}

.reason-rejection-accordions .accordion-body ul li p {
  font-size: 15px;
  font-weight: bold;
  margin: 0;
}

.reason-rejection-accordions .accordion-body ul li span {
  font-size: 15px;
  background: #00c5c6;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  color: black;
  font-weight: bold;
}

#perfect-job-poster {
  background: url('../assets/images/perfect-skill-bg.png'), #d9453d;
  /* background-position: bottom -108px center; */
  background-repeat: no-repeat;
  /* background-size: 1920px 740px; */
  /* min-height: 100vh; */
  background-position: center 202%;
  background-size: contain;
}

#perfect-job-poster .brand-logo img {
  max-width: 105px;
}

#perfect-job-poster .brand-logo {
  margin-top: 60px;
  margin-bottom: 68px;
}

.perfect-content {
  padding-bottom: 60px;
}

#perfect-job-poster .perfect-content h3 {
  font-size: 55px;
  font-weight: bold;
  margin-top: 68px;
  line-height: 63px;
}

#perfect-job-poster .perfect-content h5 {
  font-size: 55px;
  font-weight: bold;
  margin-top: 50px;
  line-height: 63px;
  padding: 0 20%;
  margin-bottom: 60px;
}

#type-personality-look {
  background: url('../assets/images/discover-footer-graphics.png'), #00c5c6;
  background-repeat: no-repeat;
  /* background-size: contain; */

  background-position: center bottom;
  min-height: 100vh;
}

.personality-looking {
  padding-top: 50px;
  position: relative;
  z-index: 2;
}

/* #type-personality-look:before {
  width: 1920px;
  background: ;
  background-size: cover;
  content: '';
  position: absolute;
  left: 0;
  bottom: -108px;
  
  z-index: 100;
} */

.personality-looking h6 {
  font-size: 55px;
  font-weight: bold;
  letter-spacing: -4px;
  line-height: 63px;
  margin-top: 30px;
  margin-bottom: 40px;
  padding: 0 6%;
}

.personality-looking a {
  display: inline-block;
  margin-top: 35px;
}

.date-picker {
  /* padding: 0 25%; */
  margin: 30px 0 55px;
}

.date-picker input {
  width: 100%;
  border: 2px solid;
  border-radius: 5px;
  padding: 12px 10px;
  font-size: 18px;
  font-weight: 500;
  text-transform: lowercase;
}

/************** congrats reacheads ********************/

#congrts-reacheds {
  background: url('../assets/images/login-pop-step-3-bg.png'), #00cca3;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 1920px 854px;
  min-height: 100vh;
}

#congrts-reacheds .brand-logo img {
  max-width: 105px;
}

#congrts-reacheds .brand-logo {
  margin-top: 60px;
  margin-bottom: 68px;
}

.congrts-reacheds .perfect-content {
  padding-bottom: 60px;
}

#congrts-reacheds .perfect-content h3 {
  font-size: 55px;
  font-weight: bold;
  margin-top: 68px;
  line-height: 63px;
}

#congrts-reacheds .perfect-content h5 {
  font-size: 55px;
  font-weight: bold;
  margin-top: 0px;
  line-height: 63px;
  padding: 0 20%;
  margin-bottom: 60px;
}

#congrts-reacheds .perfect-content p {
  padding: 0 18%;
}

/*****************************************Loader csss************************************/
.LoaderCenter {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.LoaderText {
  font-family: 'Space Mono', monospace;
  color: #000000;
  font-size: 22px;
  line-height: 32px;
  padding-left: 20px;
}

.signup-error {
  border-radius: 5px;
  position: absolute;
  top: 97%;
  color: white;
  text-align: start;
  padding-left: 5px;
  background: rgba(240, 0, 0, 0.705);
  width: 100%;
}

.PhoneInput {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  height: 68px;
  width: 100%;
  color: black;
  font-size: 15px;
  padding: 12px;
  background-color: #fff;
}

input.PhoneInputInput {
  border: 0;
  border-right: none;
  border-left: none;
}

input.PhoneInputInput:focus {
  border: 0;
}

.error-msg-card {
  width: 100%;
  text-align: left;
  color: #d9453d;
  font-size: 15px;
}

.text-area textarea {
  height: 220px;
  margin-bottom: 20px;

  padding: 10px 20px;
  font-size: 20px;
  width: -webkit-fill-available;
}

.row.company-info-container {
  margin-top: 100px;
}

.login-pop-cnt img {
  max-width: 100px;
}

.hand-logo {
  background: url('../assets/images/login-pop-step-3-bg.png'), #00cca3;
  background-size: cover;

  background-position: top;
}

div#cc-number,
#expiry,
#cvc {
  height: 68px;
  width: 100%;
  color: black;
  font-size: 15px;
  padding: 12px;
}

.__PrivateStripeElement {
  padding: 12px 0px !important;
}

.success-msg {
  color: #0f5132;
  text-align: center;
  margin-top: 120px;
}

.success-msg svg {
  font-size: 60px;
  border: 1px solid #0f5132;
  border-radius: 30px;
  padding: 10px;
}

.success-msg .title {
  font-size: 25px;
  margin-top: 10px;
}

.side-menu {
  position: absolute;
  top: 80%;
  width: 400px;
  z-index: 999;
  background-color: #ffd578;
  height: 100vh;
  /* left: 270px; */
  margin-right: -71px;
  /* position: fixed; */
  /* left: 66%; */
}

.side-menu-list {
  list-style: none;

  text-align: start;
}

.menu-list {
  text-decoration: none;
  width: 100%;
  padding: 10px;
  color: #000;
  font-size: 20px;
  text-align: start;
}

img.card-img-top {
  height: 100px;
  width: 100px;
  margin: auto;
  vertical-align: center;
}

.iconHeart {
  position: absolute;
  right: 20px;
  display: grid;
  /* background: radial-gradient(black, transparent); */
  top: 20px;
}

.default-heading {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -4px;
}

.default-subtitle-text {
  font-size: 28px;
  font-style: normal;
}

.profile-select {
  position: absolute;
  font-size: large;
  font-weight: 600;
}

.matching-button {
  margin-top: 12px;
  height: 64px;
  justify-content: center;
  align-items: center;
  font-size: large;
  font-weight: 600;
  color: #000;
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
    5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
    10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
}

.col-lg-12.recent-page-heading-jp.mb-4 {
  text-align: start;
}

.people-vector {
  position: fixed;
  bottom: 0;
  left: 0px;
}

.a.skip-text-white:hover {
  color: #fff;
}

#discover-personality-steps {
  min-height: 100vh !important;
  height: 100vh;
}

.dashboard-sidebar ul {
  margin-bottom: 242px;
}

.dashboard-nav .fa-coins {
  margin-right: 7px;
}

.wishlist {
  margin-right: 10px !important;
}

.otp-input input {
  margin: 0 12px !important;
  border-bottom: 5px solid #000 !important;
}

.get-strarted-button img {
  max-width: 200px;
}

.skip-btn {
  border: 0;
  color: #111;
  background: transparent;
}

.form-card {
  margin-top: 10px;
}

.dashboard-sidebar {
  margin-bottom: 180px;
}

@media screen and (max-width: 768px) {
  .dashboard-sidebar {
    margin-bottom: 10px;
  }

  #discover-personality-steps {
    background-position: center 30% !important;
    background: url('../assets/images/steps-top-graphics.png'),
      linear-gradient(354.65deg, #00cca3 -9.22%, #ffdb59 78.14%);
    background-repeat: no-repeat !important;
  }
}

.discover-check-cnt {
  /* max-width: 300px; */
  /* width: 1000%; */
  margin: 1px;
}

.menu-area li img {
  width: 20px;
}

.saved-form {
  height: 68px;
  width: 100%;
  color: black;
  font-size: 15px;
  padding: 12px;
  display: flex !important;
  align-items: center;
}

.enter-job-role>div>div {
  background-color: #fff;
}

/* .enter-job-role .suggestion-item span:first-child,
.suggestion-item--activefirst-child {
  margin-top: 20px;
  display: inline-block;
} */

.landing-heading {
  margin-bottom: -1%;
}

.font-space-mono {
  font-family: 'Space Mono', monospace;
}

.login-input::placeholder {
  color: #000 !important;
  font-weight: 600;
}

.job-que {
  font-size: 2rem !important;
  letter-spacing: 0px !important;
  line-height: 2.5rem !important;
}

.job-role-desc h5 {
  font-size: 2rem !important;
  line-height: 2.5rem !important;
}

.job-input {
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  color: black;
  padding: 5px 10px !important;
  width: 70%;
  text-align: center;
  background: #fff;
  /* font-size: 1rem; */
}

.job-input::placeholder {
  font-family: monospace !important;
  font-weight: 500 !important;
  font-size: 0.9rem;
  letter-spacing: 0px !important;
}

.w-80px {
  width: 80px !important;
}

.skills-step-des h3 {
  font-size: 2rem !important;
  letter-spacing: 0px !important;
  line-height: 2.5rem !important;
}

.feedback-heading {
  font-size: 1rem;
}

.MuiAccordionSummary-contentGutters {
  display: flex !important;
  justify-content: space-between !important;
  background: #ffffff !important;
}

.bg-light-green {
  background: #1ec991;
}

.feedback-list li {
  list-style: none;
}

.container-80 {
  width: 80%;
  margin: auto;
}

.enhance-section {
  display: flex;
  justify-content: flex-start;
}

.jobs-dropdown {
  top: 100%;
  border: 1px solid #4344f8;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
}

.jobs-dropdown-update {
  top: 90%;
  border: 1px solid #4344f8;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
}

@media screen and (max-width: 768px) {
  .skills-step-des h3 {
    font-size: 1.5rem !important;
    line-height: 1.8rem !important;
    margin-bottom: 5rem;
  }

  #skills-steps {
    background: url('../assets/images/job-role-step-bg.png'), #4344f8 !important;
    background-size: 100% !important;
    background-position: bottom !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
  }

  #soft-skills-steps {
    background: url('../assets/images/job-role-step-bg.png'), #d9453d !important;
    background-size: 100% !important;
    background-position: bottom !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
  }

  .jobs-dropdown {
    top: auto;
    margin-bottom: 5rem;
    background: #fff !important;
    z-index: 100;
  }

  #login-page {
    background-position: -697px -180px;
  }

  #nav-tabContent {
    margin-top: 5rem !important;
    position: relative;
  }

  .discover-check-cnt .steps-checkbox {
    position: absolute;
    top: -13%;
    left: 0%;
  }

  .first-check-box {
    top: -65% !important;
  }

  .discover-check-cnt p {
    padding-left: 0px !important;
  }

  #signup-page {
    background-size: 150% !important;
    padding-top: 50px;
    padding-bottom: 70px;
    background-position: center 0% !important;
  }

  .enhance-section {
    display: flex;
    justify-content: center;
  }

  .profile-active-sec {
    margin-top: 8%;
  }
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  background-image: none;
}

.perspectv-header {
  display: inline-block;
  /* box-shadow: 7px 12px 0 #000; */
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
  5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
  10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
  padding: 15px 40px;
  border: 1px solid #000;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: black;
  background: white;
  text-decoration: none;
}

.perspectv-button {
  display: inline-block;
  /* box-shadow: 7px 12px 0 #000; */
  box-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000,
  5px 5px 0px #000, 6px 6px 0px #000, 7px 7px 0px #000, 8px 8px 0px #000, 9px 9px 0px #000,
  10px 10px 0px #000, 11px 11px 0px #000, 12px 12px 0px #000, 13px 13px 0px #000;
  padding: 15px 40px;
  border: 1px solid #000;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: black;
  background: white;
  text-decoration: none;
  cursor: pointer;
}